export function SvgErro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function SvgChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function SvgArrow(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgArrow_2(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12M4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12M10,17L15,12L10,7V17Z"/>
        </svg>
    )
}

export function SvgArrow_3(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,7L7,12H10V16H14V12H17L12,7Z"/>
        </svg>
    )
}

export function SvgStar_1(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M5.8 21L7.4 14L2 9.2L9.2 8.6L12 2L14.8 8.6L22 9.2L18.8 12H18C17.3 12 16.6 12.1 15.9 12.4L18.1 10.5L13.7 10.1L12 6.1L10.3 10.1L5.9 10.5L9.2 13.4L8.2 17.7L12 15.4L12.5 15.7C12.3 16.2 12.1 16.8 12.1 17.3L5.8 21M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"/>
        </svg>
    )
}

export function SvgStar_2(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"/>
        </svg>
    )
}

export function SvgClose(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgDelete(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M3 6H5H21" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}