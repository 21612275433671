import React, { useState, useEffect } from "react";

import './Redirect.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

export default function PopUP_Redirect(){
    
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('Redirect', setModaldata);
        RegisterModalObserver('Redirect', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [showPopUp]);

    function TypeRemoval(){
        switch(modalData.type){
            case "open_link":
                return (
                    <div>
                        Área do cliente
                    </div>
                );
        }
    }

    function OpenLink(){
        ClosePopUp();
        window.open(modalData.link, '_blank');
    }

    function ClosePopUp(){
        SetModalState('Redirect', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all Confirmation">
                        <div className="div_data type_div">
                            <div className="title">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="opt_select yes_update" onClick={ ()=>{ OpenLink() } }>Acessar</div>
                                <div className="opt_select not_update" onClick={ ()=>{ ClosePopUp() } }>Cancelar</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
